@import "../../shared/font";

@media all  and (max-width: 768px){
  .solution-card {
    width: 250px;
    height: 220px;

    background-size: cover;
    background-repeat: no-repeat;

    padding: 10px 10px;

    display: flex;
    flex-direction: column;

    border-radius: 10px;
    margin-left: 10px;
  }

  .solution-card-title {
    margin-top: 0;

    display: flex;
    flex-direction: row;
    align-items: center;

    .solution-card-title-text {
      @include font-pretendard;
      @include font-attribute(14px, rgba(65, 60, 51, 0.80), $weight: 500);
    }

    .solution-card-title-space {
      flex-grow: 1;
    }

    p {
      @include font-pretendard;
      @include font-attribute(9px, rgba(65, 60, 51, 0.40), $weight: 400);
    }
  }

  .solution-card-sub-title {
    margin-top: 0;

    @include font-pretendard;
    @include font-attribute(11px, rgba(65, 60, 51, 0.60), $weight: 400);
  }

  .solution-card-sub-texts {
    margin-top: 0;
    margin-left: 120px;
    flex-grow: 1;
    justify-content: center;
    display: flex;
    flex-direction: column;


    p {
      margin: 0;
      padding-bottom: 8px;
      align-items: center;
      justify-content: center;
      @include font-pretendard;
      @include font-attribute(10px, rgba(65, 60, 51, 0.95), $weight: 500);
    }
  }

  .solution-card-bottom-notice {
    text-align: right;

    margin-top: auto;
    margin-bottom: 0;

    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: right;

    p {
      margin: 0;

      @include font-pretendard;
      @include font-attribute(8px, rgba(65, 60, 51, 0.60), $weight: 400);
    }
  }
}

@media all and (min-width: 769px) {
  .solution-card {
    width: 250px;
    height: 400px;

    background-size: cover;
    background-repeat: no-repeat;

    padding: 13px 25px;

    display: flex;
    flex-direction: column;

    border-radius: 10px;
  }

  .solution-card-title {
    margin-top: 185px;

    display: flex;
    flex-direction: row;
    align-items: center;

    .solution-card-title-text {
      @include font-pretendard;
      @include font-attribute(14px, rgba(65, 60, 51, 0.80), $weight: 500);
    }

    .solution-card-title-space {
      flex-grow: 1;
    }

    p {
      @include font-pretendard;
      @include font-attribute(9px, rgba(65, 60, 51, 0.40), $weight: 400);
    }
  }

  .solution-card-sub-title {
    margin-top: 0;

    @include font-pretendard;
    @include font-attribute(12px, rgba(65, 60, 51, 0.60), $weight: 500);
  }

  .solution-card-sub-texts {
    margin-top: 16px;

    p {
      margin: 0;
      padding-bottom: 8px;

      @include font-pretendard;
      @include font-attribute(12px, rgba(65, 60, 51, 0.95), $weight: 500);
    }
  }

  .solution-card-bottom-notice {
    text-align: right;

    margin-top: auto;
    margin-bottom: 0;

    p {
      margin: 0;

      @include font-pretendard;
      @include font-attribute(8px, rgba(65, 60, 51, 0.60), $weight: 400);
    }
  }

}


.solution-card-top-notice {
  @include font-pretendard;
  @include font-attribute(10px, rgba(65, 60, 51, 0.40), $weight: 500);
}
